import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import ScrollUpButton from "react-scroll-up-button";
import Layout from "../../components/layoutDoc";
import Seo from "../../components/seo";
import { Link } from 'gatsby';

import Download from '../../images/inline/download.inline.svg';
import PNG from '../../images/branding/Png.inline.svg';
import SVG from '../../images/branding/Svg.inline.svg';
import ICON from '../../images/branding/Icon.inline.svg';
import Circle from '../../images/branding/brandLogoCircle.inline.svg'
import RectLogo from '../../images/branding/brandLogoRect.inline.png'
// import LogoText from '../../images/branding/brandLogoText.inline.svg'
import LogoIcon from '../../images/branding/brandLogoIcon.inline.svg'


function Branding() {
    return (
        <Layout page="Branding" pageInner="Branding">
            <ScrollUpButton ContainerClassName="custom-scroll-button" />
            <Seo title="Branding" />
            <Container fluid='lg' className="py-md-5 px-lg-5 py-4">
                <Row className="justify-content-center mb-5 text-center">
                    <Col lg={12}>
                        <div className="section-title">
                            <h2>HedgePay Branding</h2>
                        </div>
                    </Col>
                </Row>

                <Row className='mb-5 branding-logos text-center'>
                    <Col md={4} className="mb-md-0 mb-4">
                        <div style={{margin: '0 auto', maxWidth: '150px'}}>
                            <Circle />

                            <div className='d-flex mt-3 align-items-center justify-content-around'>
                                <Link to="https://drive.google.com/file/d/1EqaP9WVHQv_ZHyyLF7eky3-MxV5GcQmA/view?usp=sharing" target={"_blank"}><PNG /></Link>
                                <Link to="https://drive.google.com/file/d/1zvBmiRct5kh4aPPlzbTcFoRF-Nr8rJ5t/view?usp=sharing" target={"_blank"} className="ml-3"><SVG /></Link>
                            </div>
                        </div>
                    </Col>
                    <Col md={4} className="mb-md-0 mb-4">
                        {/* <RectLogo /> */}
                        <div style={{margin: '0 auto', maxWidth: '150px'}}>
                            <img src={RectLogo} alt="image" />

                            <div className='d-flex mt-3 align-items-center justify-content-around'>
                                <Link to="https://drive.google.com/file/d/11yIlAvLMW3XIrYfg0ruHNuYlw2qsN_Gb/view?usp=sharing" target={"_blank"}><PNG /></Link>
                                <Link to="https://drive.google.com/file/d/1IoRCxEzxvcxcs8eGQ_rpxpfaxMXWKEvD/view?usp=sharing" target={"_blank"} className="ml-3"><SVG /></Link>
                            </div>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className='mb-4'>
                            <div style={{margin: '0 auto', maxWidth: '260px'}}>
                                <LogoIcon />

                                <div className='d-flex mt-3 align-items-center justify-content-center'>
                                    <Link to="https://drive.google.com/file/d/1rtWySoFYA9ZcM_pGzDdmnOV3DvIu_Osa/view?usp=sharing" target={"_blank"}><PNG /></Link>
                                    <Link style={{marginLeft: '15px'}} to="https://drive.google.com/file/d/1ofoL-CNiBI6yZB2jYS7t7TfOureEvtqd/view?usp=sharing" target={"_blank"} className="ml-3"><SVG /></Link>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div style={{margin: '0 auto', maxWidth: '260px'}}>
                                <LogoIcon />

                                <div className='d-flex mt-3 align-items-center justify-content-center'>
                                    <Link to="https://drive.google.com/file/d/1eymSkSKai13FB_C75xxRKj6aLeO0C237/view?usp=sharing" target={"_blank"}><ICON /></Link>
                                    <Link style={{marginLeft: '15px'}} to="https://drive.google.com/file/d/1ofoL-CNiBI6yZB2jYS7t7TfOureEvtqd/view?usp=sharing" target={"_blank"} className="ml-3"><SVG /></Link>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

                <div className='mb-5 text-center'>
                    <Link className='btn py-12 px-4 btn-secondary d-inline-flex align-items-center' to="https://drive.google.com/drive/folders/1ijbAHI5KY58FJyLjkH4zfwNG5N_j8Mfe" target={"_blank"}><Download /> <span className='ms-3'>Download All</span></Link>
                </div>

                <div className='branding-content'>
                    <h3>Guidelines</h3>
                    <p>Thank you for joining HedgePay ecosystem. Below are the HedgePay branding guidelines to follow:</p>
                    <ol>
                        <li>You may only use the official logos on section above or in this <Link to="/">Google Drive</Link></li>
                        <li>You are not permitted to edit HedgePay logo into a different color or theme</li>
                        <li>Do not put any form of partnership with HedgePay unless you are permitted</li>
                    </ol>

                    <h3>Meaning Behind</h3>
                    <p>We kept the HedgePay branding clean and simple with minimal design. Our basic color themes are emerald green and gold. <br />The HedgePay logo has two very particular meanings:</p>
                    <ol>
                        <li>The infinity symbol represents HedgePay’s unique and unlimited reward system</li>
                        <li>The bridge with paired lines in the middle of the infinity symbol, represents HedgePay’s very strong bond and relationship among organizations</li>
                    </ol>
                </div>
            </Container>
        </Layout>
    );
}

export default Branding;
